import monaco from 'monaco-editor';

// Generated using the VSCode GitHub theme locally, "Developer: Generate color theme from current settings" command
// Then converted using https://github.com/Nishkalkashyap/monaco-vscode-textmate-theme-converter

export const QuadraticEditorTheme: monaco.editor.IStandaloneThemeData = {
  inherit: false,
  base: 'vs',
  colors: {
    'activityBar.activeBorder': '#fd8c73',
    'activityBar.background': '#ffffff',
    'activityBar.border': '#d0d7de',
    'activityBar.foreground': '#24292f',
    'activityBar.inactiveForeground': '#57606a',
    'activityBarBadge.background': '#0969da',
    'activityBarBadge.foreground': '#ffffff',
    'badge.background': '#0969da',
    'badge.foreground': '#ffffff',
    'breadcrumb.activeSelectionForeground': '#57606a',
    'breadcrumb.focusForeground': '#24292f',
    'breadcrumb.foreground': '#57606a',
    'breadcrumbPicker.background': '#ffffff',
    'button.background': '#2da44e',
    'button.foreground': '#ffffff',
    'button.hoverBackground': '#2c974b',
    'button.secondaryBackground': '#ebecf0',
    'button.secondaryForeground': '#24292f',
    'button.secondaryHoverBackground': '#f3f4f6',
    'checkbox.background': '#f6f8fa',
    'checkbox.border': '#d0d7de',
    'debugToolBar.background': '#ffffff',
    descriptionForeground: '#57606a',
    'diffEditor.insertedTextBackground': '#dafbe1',
    'diffEditor.removedTextBackground': '#ffebe9',
    'dropdown.background': '#ffffff',
    'dropdown.border': '#d0d7de',
    'dropdown.foreground': '#24292f',
    'dropdown.listBackground': '#ffffff',
    'editor.background': '#ffffff',
    'editor.findMatchBackground': '#bf8700',
    'editor.findMatchHighlightBackground': '#ffdf5d66',
    'editor.focusedStackFrameHighlightBackground': '#28a74525',
    'editor.foldBackground': '#6e77811a',
    'editor.foreground': '#24292f',
    'editor.inactiveSelectionBackground': '#0366d611',
    'editor.lineHighlightBackground': '#eaeef280',
    'editor.linkedEditingBackground': '#0366d611',
    'editor.selectionBackground': '#0366d625',
    'editor.selectionHighlightBackground': '#34d05840',
    'editor.selectionHighlightBorder': '#34d05800',
    'editor.stackFrameHighlightBackground': '#ffd33d33',
    'editor.wordHighlightBackground': '#34d05800',
    'editor.wordHighlightBorder': '#24943e99',
    'editor.wordHighlightStrongBackground': '#34d05800',
    'editor.wordHighlightStrongBorder': '#24943e50',
    'editorBracketMatch.background': '#34d05840',
    'editorBracketMatch.border': '#34d05800',
    'editorCursor.foreground': '#0969da',
    'editorGroup.border': '#d0d7de',
    'editorGroupHeader.tabsBackground': '#f6f8fa',
    'editorGroupHeader.tabsBorder': '#d0d7de',
    'editorGutter.addedBackground': '#4ac26b66',
    'editorGutter.deletedBackground': '#ff818266',
    'editorGutter.modifiedBackground': '#d4a72c66',
    'editorIndentGuide.activeBackground': '#d0d7de',
    'editorIndentGuide.background': '#d8dee4',
    'editorLineNumber.activeForeground': '#24292f',
    'editorLineNumber.foreground': '#57606a',
    'editorOverviewRuler.border': '#ffffff',
    'editorWhitespace.foreground': '#6e7781',
    'editorWidget.background': '#ffffff',
    errorForeground: '#cf222e',
    focusBorder: '#0969da',
    foreground: '#24292f',
    'gitDecoration.addedResourceForeground': '#1a7f37',
    'gitDecoration.conflictingResourceForeground': '#bc4c00',
    'gitDecoration.deletedResourceForeground': '#cf222e',
    'gitDecoration.ignoredResourceForeground': '#6e7781',
    'gitDecoration.modifiedResourceForeground': '#9a6700',
    'gitDecoration.submoduleResourceForeground': '#57606a',
    'gitDecoration.untrackedResourceForeground': '#1a7f37',
    'input.background': '#ffffff',
    'input.border': '#d0d7de',
    'input.foreground': '#24292f',
    'input.placeholderForeground': '#6e7781',
    'list.activeSelectionBackground': '#afb8c133',
    'list.activeSelectionForeground': '#24292f',
    'list.focusBackground': '#ddf4ff',
    'list.focusForeground': '#24292f',
    'list.highlightForeground': '#0969da',
    'list.hoverBackground': '#eaeef280',
    'list.hoverForeground': '#24292f',
    'list.inactiveFocusBackground': '#ddf4ff',
    'list.inactiveSelectionBackground': '#afb8c133',
    'list.inactiveSelectionForeground': '#24292f',
    'notificationCenterHeader.background': '#f6f8fa',
    'notificationCenterHeader.foreground': '#57606a',
    'notifications.background': '#ffffff',
    'notifications.border': '#d0d7de',
    'notifications.foreground': '#24292f',
    'notificationsErrorIcon.foreground': '#cf222e',
    'notificationsInfoIcon.foreground': '#0969da',
    'notificationsWarningIcon.foreground': '#9a6700',
    'panel.background': '#f6f8fa',
    'panel.border': '#d0d7de',
    'panelInput.border': '#d0d7de',
    'panelTitle.activeBorder': '#fd8c73',
    'panelTitle.activeForeground': '#24292f',
    'panelTitle.inactiveForeground': '#57606a',
    'pickerGroup.border': '#d0d7de',
    'pickerGroup.foreground': '#57606a',
    'progressBar.background': '#0969da',
    'quickInput.background': '#ffffff',
    'quickInput.foreground': '#24292f',
    'scrollbar.shadow': '#6a737d33',
    'scrollbarSlider.activeBackground': '#959da588',
    'scrollbarSlider.background': '#959da533',
    'scrollbarSlider.hoverBackground': '#959da544',
    'settings.headerForeground': '#57606a',
    'settings.modifiedItemIndicator': '#d4a72c66',
    'sideBar.background': '#f6f8fa',
    'sideBar.border': '#d0d7de',
    'sideBar.foreground': '#24292f',
    'sideBarSectionHeader.background': '#f6f8fa',
    'sideBarSectionHeader.border': '#d0d7de',
    'sideBarSectionHeader.foreground': '#24292f',
    'sideBarTitle.foreground': '#24292f',
    'statusBar.background': '#ffffff',
    'statusBar.border': '#d0d7de',
    'statusBar.debuggingBackground': '#cf222e',
    'statusBar.debuggingForeground': '#ffffff',
    'statusBar.foreground': '#57606a',
    'statusBar.noFolderBackground': '#ffffff',
    'statusBarItem.prominentBackground': '#f6f8fa',
    'tab.activeBackground': '#ffffff',
    'tab.activeBorder': '#ffffff',
    'tab.activeBorderTop': '#fd8c73',
    'tab.activeForeground': '#24292f',
    'tab.border': '#d0d7de',
    'tab.hoverBackground': '#ffffff',
    'tab.inactiveBackground': '#f6f8fa',
    'tab.inactiveForeground': '#57606a',
    'tab.unfocusedActiveBorder': '#ffffff',
    'tab.unfocusedActiveBorderTop': '#d0d7de',
    'tab.unfocusedHoverBackground': '#eaeef280',
    'terminal.ansiBlack': '#24292f',
    'terminal.ansiBlue': '#0969da',
    'terminal.ansiBrightBlack': '#57606a',
    'terminal.ansiBrightBlue': '#218bff',
    'terminal.ansiBrightCyan': '#3192aa',
    'terminal.ansiBrightGreen': '#1a7f37',
    'terminal.ansiBrightMagenta': '#a475f9',
    'terminal.ansiBrightRed': '#a40e26',
    'terminal.ansiBrightWhite': '#8c959f',
    'terminal.ansiBrightYellow': '#633c01',
    'terminal.ansiCyan': '#1b7c83',
    'terminal.ansiGreen': '#116329',
    'terminal.ansiMagenta': '#8250df',
    'terminal.ansiRed': '#cf222e',
    'terminal.ansiWhite': '#6e7781',
    'terminal.ansiYellow': '#4d2d00',
    'terminal.foreground': '#57606a',
    'textBlockQuote.background': '#f6f8fa',
    'textBlockQuote.border': '#d0d7de',
    'textCodeBlock.background': '#afb8c133',
    'textLink.activeForeground': '#0969da',
    'textLink.foreground': '#0969da',
    'textPreformat.foreground': '#57606a',
    'textSeparator.foreground': '#d8dee4',
    'titleBar.activeBackground': '#ffffff',
    'titleBar.activeForeground': '#57606a',
    'titleBar.border': '#d0d7de',
    'titleBar.inactiveBackground': '#f6f8fa',
    'titleBar.inactiveForeground': '#57606a',
    'tree.indentGuidesStroke': '#d8dee4',
  },
  rules: [
    {
      foreground: '#6E7781',
      token: 'comment',
    },
    {
      foreground: '#6E7781',
      token: 'punctuation.definition.comment',
    },
    {
      foreground: '#6E7781',
      token: 'string.comment',
    },
    {
      foreground: '#0550AE',
      token: 'constant',
    },
    {
      foreground: '#0550AE',
      token: 'entity.name.constant',
    },
    {
      foreground: '#0550AE',
      token: 'variable.other.constant',
    },
    {
      foreground: '#0550AE',
      token: 'variable.language',
    },
    {
      foreground: '#0550AE',
      token: 'entity',
    },
    {
      foreground: '#953800',
      token: 'entity.name',
    },
    {
      foreground: '#953800',
      token: 'meta.export.default',
    },
    {
      foreground: '#953800',
      token: 'meta.definition.variable',
    },
    {
      foreground: '#24292F',
      token: 'variable.parameter.function',
    },
    {
      foreground: '#24292F',
      token: 'meta.jsx.children',
    },
    {
      foreground: '#24292F',
      token: 'meta.block',
    },
    {
      foreground: '#24292F',
      token: 'meta.tag.attributes',
    },
    {
      foreground: '#24292F',
      token: 'entity.name.constant',
    },
    {
      foreground: '#24292F',
      token: 'meta.object.member',
    },
    {
      foreground: '#24292F',
      token: 'meta.embedded.expression',
    },
    {
      foreground: '#8250DF',
      token: 'entity.name.function',
    },
    {
      foreground: '#116329',
      token: 'entity.name.tag',
    },
    {
      foreground: '#116329',
      token: 'support.class.component',
    },
    {
      foreground: '#CF222E',
      token: 'keyword',
    },
    {
      foreground: '#CF222E',
      token: 'storage',
    },
    {
      foreground: '#CF222E',
      token: 'storage.type',
    },
    {
      foreground: '#24292F',
      token: 'storage.modifier.package',
    },
    {
      foreground: '#24292F',
      token: 'storage.modifier.import',
    },
    {
      foreground: '#24292F',
      token: 'storage.type.java',
    },
    {
      foreground: '#0A3069',
      token: 'string',
    },
    {
      foreground: '#0A3069',
      token: 'punctuation.definition.string',
    },
    {
      foreground: '#0A3069',
      token: 'string punctuation.section.embedded source',
    },
    {
      foreground: '#0550AE',
      token: 'support',
    },
    {
      foreground: '#0550AE',
      token: 'meta.property-name',
    },
    {
      foreground: '#953800',
      token: 'variable',
    },
    {
      foreground: '#24292F',
      token: 'variable.other',
    },
    {
      foreground: '#82071E',
      fontStyle: 'italic',
      token: 'invalid.broken',
    },
    {
      foreground: '#82071E',
      fontStyle: 'italic',
      token: 'invalid.deprecated',
    },
    {
      foreground: '#82071E',
      fontStyle: 'italic',
      token: 'invalid.illegal',
    },
    {
      foreground: '#82071E',
      fontStyle: 'italic',
      token: 'invalid.unimplemented',
    },
    {
      foreground: '#F6F8FA',
      background: '#CF222E',
      fontStyle: 'italic underline',
      token: 'carriage-return',
    },
    {
      foreground: '#82071E',
      token: 'message.error',
    },
    {
      foreground: '#24292F',
      token: 'string source',
    },
    {
      foreground: '#0550AE',
      token: 'string variable',
    },
    {
      foreground: '#0A3069',
      token: 'source.regexp',
    },
    {
      foreground: '#0A3069',
      token: 'string.regexp',
    },
    {
      foreground: '#0A3069',
      token: 'string.regexp.character-class',
    },
    {
      foreground: '#0A3069',
      token: 'string.regexp constant.character.escape',
    },
    {
      foreground: '#0A3069',
      token: 'string.regexp source.ruby.embedded',
    },
    {
      foreground: '#0A3069',
      token: 'string.regexp string.regexp.arbitrary-repitition',
    },
    {
      foreground: '#116329',
      fontStyle: 'bold',
      token: 'string.regexp constant.character.escape',
    },
    {
      foreground: '#0550AE',
      token: 'support.constant',
    },
    {
      foreground: '#0550AE',
      token: 'support.variable',
    },
    {
      foreground: '#0550AE',
      token: 'meta.module-reference',
    },
    {
      foreground: '#953800',
      token: 'punctuation.definition.list.begin.markdown',
    },
    {
      foreground: '#0550AE',
      fontStyle: 'bold',
      token: 'markup.heading',
    },
    {
      foreground: '#0550AE',
      fontStyle: 'bold',
      token: 'markup.heading entity.name',
    },
    {
      foreground: '#116329',
      token: 'markup.quote',
    },
    {
      foreground: '#24292F',
      fontStyle: 'italic',
      token: 'markup.italic',
    },
    {
      foreground: '#24292F',
      fontStyle: 'bold',
      token: 'markup.bold',
    },
    {
      foreground: '#0550AE',
      token: 'markup.raw',
    },
    {
      foreground: '#82071E',
      background: '#FFEBE9',
      token: 'markup.deleted',
    },
    {
      foreground: '#82071E',
      background: '#FFEBE9',
      token: 'meta.diff.header.from-file',
    },
    {
      foreground: '#82071E',
      background: '#FFEBE9',
      token: 'punctuation.definition.deleted',
    },
    {
      foreground: '#116329',
      background: '#DAFBE1',
      token: 'markup.inserted',
    },
    {
      foreground: '#116329',
      background: '#DAFBE1',
      token: 'meta.diff.header.to-file',
    },
    {
      foreground: '#116329',
      background: '#DAFBE1',
      token: 'punctuation.definition.inserted',
    },
    {
      foreground: '#953800',
      background: '#FFD8B5',
      token: 'markup.changed',
    },
    {
      foreground: '#953800',
      background: '#FFD8B5',
      token: 'punctuation.definition.changed',
    },
    {
      foreground: '#EAEEF2',
      background: '#0550AE',
      token: 'markup.ignored',
    },
    {
      foreground: '#EAEEF2',
      background: '#0550AE',
      token: 'markup.untracked',
    },
    {
      foreground: '#8250DF',
      fontStyle: 'bold',
      token: 'meta.diff.range',
    },
    {
      foreground: '#0550AE',
      token: 'meta.diff.header',
    },
    {
      foreground: '#0550AE',
      fontStyle: 'bold',
      token: 'meta.separator',
    },
    {
      foreground: '#0550AE',
      token: 'meta.output',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.tag',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.curly',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.round',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.square',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.angle',
    },
    {
      foreground: '#57606A',
      token: 'brackethighlighter.quote',
    },
    {
      foreground: '#82071E',
      token: 'brackethighlighter.unmatched',
    },
    {
      foreground: '#0A3069',
      fontStyle: 'underline',
      token: 'constant.other.reference.link',
    },
    {
      foreground: '#0A3069',
      fontStyle: 'underline',
      token: 'string.other.link',
    },
    {
      foreground: '#316BCD',
      token: 'token.info-token',
    },
    {
      foreground: '#CD9731',
      token: 'token.warn-token',
    },
    {
      foreground: '#CD3131',
      token: 'token.error-token',
    },
    {
      foreground: '#800080',
      token: 'token.debug-token',
    },
  ],
  encodedTokensColors: [],
};
