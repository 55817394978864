import { qdb } from '../db';
import QuadraticDependencyGraph from '../../dgraph/QuadraticDependencyGraph';

export const GetDGraphDB = async (): Promise<QuadraticDependencyGraph> => {
  let grid = await qdb.qgrid.get(1);
  let qdg = new QuadraticDependencyGraph();

  if (grid !== undefined) {
    // load previously created dgraph
    qdg.load_from_json(grid?.dgraph_json || '');
  } else {
    // init
    qdb.qgrid.add({
      id: 1,
      dgraph_json: qdg.export_to_json(),
    });
  }
  return qdg;
};
